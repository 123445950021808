<template>
  <div>
    <div v-show="!paymentForm">
      <h3>{{ $t('Products') }}</h3>
      <div class="col-12 card sticky" v-if="cart2.length > 0">
        <div class="col-12" v-if="getCoupon(products[0])">
            <span class="row justify-content-center">
              {{ $t('Coupon') }}: {{ getCoupon(products[0]) }} &nbsp;&nbsp;
              <base-button type="danger" size="sm" round icon @click="couponRemove(products[0])">
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </span>
          </div>
          <div class="col-12">
            <form @submit.prevent="couponCheck(0)">
              <span class="row justify-content-center">
                <div v-if="!getCoupon(products[0])" class="col-lg-offset-0 col-lg-2 col-md-3 col-sm-6 col-8">
                  <base-input
                    v-model="productCoupons[0]"
                    :placeholder="$t('Product Coupon')"
                    addon-left-icon="tim-icons icon-tag"
                  ></base-input>
                </div>
                <div v-if="!getCoupon(products[0])" class="col-lg-2 col-md-3 col-sm-6 col-4">
                  <base-button native-type="submit" type="default" size="sm" block>
                    {{ $t('Apply') }}
                  </base-button>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <base-button native-type="button" type="primary" size="sm" block @click="paymentForm = true">
                    {{ $t('Buy Now') }} <s v-if="totalDiscount > 0" style="padding: 0px 10px;">{{ totalBefore }} {{ resources.currency }}</s> {{ total }} {{ resources.currency }}
                  </base-button>
                </div>
              </span>
            </form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <card>
            <div>
              <h3>{{ $t('Order Details') }}</h3>
              <form @submit.prevent="submit">
                <div class="row" v-for="(field, index) in cart2[0].fields" :key="index">
                  <label class="col-md-3 col-form-label">
                    <multiLines v-html="n2br(field.name) || 'N/A'"></multiLines>
                    <a v-if="field.help" :href="field.help" target="_blank">
                      <i class="tim-icons icon-support-17"></i>
                    </a>
                  </label>
                  <div class="col-md-9">
                    <base-input v-model="cart2[0].fields[index].input"> </base-input>
                  </div>
                </div>
              </form>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div v-show="paymentForm">
      <card>
        <template slot="header">
          <h5 class="card-category">{{ $t('Payment Methods') }}</h5>
          <h3 class="card-title">{{ $t('Select Your Payment Method') }}</h3>
        </template>
        <tabs
          type="primary"
          tabNavWrapperClasses="col-lg-3 col-md-6"
          tabContentClasses="col-md-8"
          vertical
          square
          class="row"
        >
          <tab-pane>
            <span slot="label">{{ $t('Legal Terms') }}</span>
            <base-checkbox v-model="checks.terms">{{ $t('I Accept') }} <a href="https://originserver.co/terms/">{{ $t('TERMS OF SERVICE') }}</a> &amp; <a href="https://originserver.co/privacy/">{{ $t('PRIVACY POLICY') }}</a></base-checkbox>
    <!--    <base-checkbox v-model="checks.privacy">{{ $t('I Accept') }} <a href="https://originserver.co/privacy/">{{ $t('PRIVACY POLICY') }}</a></base-checkbox> -->
            <div>
              <h5 class="card-category" v-if="checks.terms">
                {{ $t('paymentMethodSelect') }}
              </h5>
            </div>
          </tab-pane>

          <tab-pane v-if="resources.pg.pp && checks.terms"> <!-- Paypal -->
            <div style="width: 100%;" slot="label" @click="payment = 'paypal'">
              <img class="paymentlogo" src="img/pp.png">
              {{ $t('PayPal') }}
            </div>
            <h5 class="card-category">{{ $t('redirectToPaymentPage') }}</h5>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.paypal }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
            <h5 class="card-category">
              {{ $t('paypalNote') }} {{ resources.paypalCurrency }}<br />
              {{ $t('paypalChargeAs') }} {{ (resources.paypalRate * totalWithFee).toFixed(2) }} {{ resources.paypalCurrency }}
            </h5>
          </tab-pane>

          <tab-pane v-if="resources.pg.pww && checks.terms"> <!-- Paymentwall widget -->
            <div style="width: 100%;" slot="label" @click="payment = 'paymentwallwidget'">
              <img class="paymentlogo" src="img/pw.png">
              <!-- <i class="tim-icons icon-bank"></i> -->
              {{ $t('Sepa') }}/{{ $t('iDeal') }}/{{ $t('Bancontact') }}
            </div>
            <h5 class="card-category">{{ $t('redirectToPaymentPage') }}</h5>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.paymentwallwidget }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.pw && checks.terms"> <!-- Credit Card -->
            <div style="width: 100%;" slot="label" @click="payment = 'paymentwall'">
              <img class="paymentlogo" src="img/cc.png">
              <!-- <i class="tim-icons icon-credit-card"></i> -->
              {{ $t('CreditCardOnSite') }}
            </div>
            <form @submit.prevent="buyNow">
              <div class="row">
                <label class="col-md-3 col-form-label">{{ $t('Card Number') }}</label>
                <div class="col-md-9">
                  <base-input name="cardNumber" v-model="card.number"> </base-input>
                </div>
              </div>

              <div class="row">
                <label class="col-md-3 col-form-label">{{ $t('Expiry Year') }}</label>
                <div class="col-md-9">
                  <base-input name="expiryYear" v-model="card.year"> </base-input>
                </div>
              </div>

              <div class="row">
                <label class="col-md-3 col-form-label">{{ $t('Expiry Month') }}</label>
                <div class="col-md-9">
                  <base-input name="expiryMonth" v-model="card.month"> </base-input>
                </div>
              </div>

              <div class="row">
                <label class="col-md-3 col-form-label">{{ $t('CVV') }}</label>
                <div class="col-md-9">
                  <base-input name="cvv" v-model="card.cvv"> </base-input>
                </div>
              </div>

              <table>
                <tr>
                  <td>{{ $t('Subtotal') }}</td>
                  <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
                </tr>
                <tr>
                  <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.paymentwall }}%)</td>
                  <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
                </tr>
                <tr>
                  <td>{{ $t('Total') }}</td>
                  <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
                </tr>
              </table>
              <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}">
                {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
              </button>
              <base-alert type="info" v-show="!payButton"> {{ $t('cardChecking') }}</base-alert>
            </form>
          </tab-pane>

          <tab-pane v-if="resources.pg.b && checks.terms"> <!-- Bank -->
            <div style="width: 100%;" slot="label" @click="payment = 'bank'">
              <img class="paymentlogo" src="img/banktransfer.png">
              {{ $t('Bank Wire') }}
            </div>
            <h5 class="card-category">{{ $t('sendAmount') }}.</h5>
            <p>
              <span v-html="resources.pm.bank"></span>
            </p>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.bank }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <base-checkbox v-model="checks.manualpayment">{{ $t('I Sent the Payment') }}</base-checkbox>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}" v-if="checks.manualpayment">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.w && checks.terms"> <!-- Western Union -->
            <div style="width: 100%;" slot="label" @click="payment = 'westernunion'">
              <img class="paymentlogo" src="img/wu.png">
              {{ $t('Western Union') }}
            </div>
            <h5 class="card-category">{{ $t('sendAmount') }}.</h5>
            <p>
              <span v-html="resources.pm.westernunion"></span>
            </p>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.westernunion }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <base-checkbox v-model="checks.manualpayment">{{ $t('I Sent the Payment') }}</base-checkbox>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}" v-if="checks.manualpayment">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.m && checks.terms"> <!-- MoneyGram -->
            <div style="width: 100%;" slot="label" @click="payment = 'moneygram'">
              <img class="paymentlogo" src="img/mg.png">
              {{ $t('MoneyGram') }}
            </div>
            <h5 class="card-category">{{ $t('sendAmount') }}.</h5>
            <p>
              <span v-html="resources.pm.moneygram"></span>
            </p>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.moneygram }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <base-checkbox v-model="checks.manualpayment">{{ $t('I Sent the Payment') }}</base-checkbox>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}" v-if="checks.manualpayment">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.t && checks.terms"> <!-- Transferwize -->
            <div style="width: 100%;" slot="label" @click="payment = 'transferwize'">
              <img class="paymentlogo" src="img/transferwise.jpg">
              {{ $t('transferwize') }}
            </div>
            <h5 class="card-category">{{ $t('sendAmount') }}.</h5>
            <p>
              <span v-html="resources.pm.transferwize"></span>
            </p>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.transferwize }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <base-checkbox v-model="checks.manualpayment">{{ $t('I Sent the Payment') }}</base-checkbox>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}" v-if="checks.manualpayment">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.c && checks.terms"> <!-- Crypto -->
            <div style="width: 100%;" slot="label" @click="payment = 'crypto'">
              <img class="paymentlogo" src="img/bitcoin.png">
              {{ $t('Crypto') }}
            </div>
            <h5 class="card-category">{{ $t('sendAmount') }}.</h5>
            <p>
              <span v-html="resources.pm.crypto"></span>
            </p>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.crypto }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <base-checkbox v-model="checks.manualpayment">{{ $t('I Sent the Payment') }}</base-checkbox>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}" v-if="checks.manualpayment">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.ps && resources.pgps.includes('ideal_nl') && checks.terms"> <!-- Payssion iDeal -->
            <div style="width: 100%;" slot="label" @click="payment = 'ideal'">
              <img class="paymentlogo" src="img/ideal_nl.png">
              {{ $t('iDeal') }}
            </div>
            <h5 class="card-category">{{ $t('redirectToPaymentPage') }}</h5>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.payssion }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.ps && resources.pgps.includes('bancontact_be') && checks.terms"> <!-- Payssion Bancontact -->
            <div style="width: 100%;" slot="label" @click="payment = 'bancontact'">
              <img class="paymentlogo" src="img/bancontact_be.png">
              {{ $t('Bancontact') }}
            </div>
            <h5 class="card-category">{{ $t('redirectToPaymentPage') }}</h5>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.payssion }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.ps && resources.pgps.includes('sofort') && checks.terms"> <!-- Payssion Sofort -->
            <div style="width: 100%;" slot="label" @click="payment = 'sofort'">
              <img class="paymentlogo" src="img/sofort.png">
              {{ $t('Sofort') }}
            </div>
            <h5 class="card-category">{{ $t('redirectToPaymentPage') }}</h5>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.payssion }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>

          <tab-pane v-if="resources.pg.ps && checks.terms"> <!-- Payssion Bitcoin -->
            <div style="width: 100%;" slot="label" @click="payment = 'bitcoin'">
              <img class="paymentlogo" src="img/bitcoin.png">
              {{ $t('Bitcoin') }}
            </div>
            <h5 class="card-category">{{ $t('redirectToPaymentPage') }}</h5>
            <table>
              <tr>
                <td>{{ $t('Subtotal') }}</td>
                <td style="text-align: right;">{{ total }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Payment Method Fee') }} ({{ resources.pfee.payssion }}%)</td>
                <td style="text-align: right;">{{ fee }} {{ resources.currency }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total') }}</td>
                <td style="text-align: right;">{{ totalWithFee }} {{ resources.currency }}</td>
              </tr>
            </table>
            <button class="btn btn-twitter" @click="buyNow" :class="{disabled: !payButton}">
              {{ $t('Buy Now') }} {{ totalWithFee }} {{ resources.currency }}
            </button>
          </tab-pane>
        </tabs>
      </card>
    </div>
  </div>  
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import swal from 'sweetalert2';
import { Modal, BaseAlert } from 'src/components';

export default {
  name: 'my-products',
  data() {
    return {
      megaCoupon: null,
      paymentForm: false,
      payButton: true,
      payment: 'paypal',
      originalOrder: 0,
      products: [],
      cart2: [],
      card: {
        "number": "",
        "month": "",
        "year": "",
        "cvv": ""
      },
      totalCoupon: null,
      productCoupons: [],
      totalCouponAccepted: false,
      checks:{
        terms: false,
        privacy: false
      },
      totalDiscount: 0
    };
  },
  components: {
    BaseAlert,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem
  },
  computed:{
    ...mapState([
      'resources',
      'toReorder'
    ]),
    ...mapGetters([
    ]),
    totalBefore() {
        let total = 0;
        this.cart2.forEach(element => {
            total += this.productSubtotal2(element);
        });
        return total;
    },
    total() {
        let discount = 1 - (this.totalDiscount / 100);
        return (this.totalBefore * discount).toFixed(2);
    },
    fee(){
      let id = (this.payment == 'ideal' || this.payment == 'bancontact' || this.payment == 'sofort' || this.payment == 'bitcoin')? 'payssion':this.payment;
      let fee = this.resources.pfee[id] / 100;
      return (this.total*fee).toFixed(2);
    },
    totalWithFee(){
      let id = (this.payment == 'ideal' || this.payment == 'bancontact' || this.payment == 'sofort' || this.payment == 'bitcoin')? 'payssion':this.payment;
      let fee = 1 + (this.resources.pfee[id] / 100);
      return (this.total*fee).toFixed(2);
    }
  },
  methods: {
    ...mapMutations([
      'setToReorder'
    ]),
    ...mapActions([
      'apiResources'
    ]),
    addProduct2(product){
        let arrayIndex = this.cartIndex(product.id);
        if(arrayIndex == -1){
            this.cart2.push(product);
            arrayIndex = this.cartIndex(product.id);
            this.cart2[arrayIndex].count = 0;
        }
        this.cart2[arrayIndex].count++;
        this.cart2.sort();
    },
    delProduct2(product){
        let arrayIndex = this.cartIndex(product.id);
        if (arrayIndex >= 0) {
            this.cart2[arrayIndex].count--;
              if(this.cart2[arrayIndex].count <= 0) this.cart2.splice(arrayIndex, 1);
        }
        this.cart2.sort();
    },
    productCount2(product){ // FIXME: Remove me
        let arrayIndex = this.cartIndex(product.id);
        if (arrayIndex >= 0) {
            return this.cart2[arrayIndex].count;
        }
        return 0;
    },
    productSubtotal2(product){
        product.price = product.sell;
        if(product.count > 0){
            product.prices.forEach(element => {
                if (product.count >= element.count) product.price = element.sell;
            });
        }
        let discount = (product.discount)? 1-(product.discount/100):1;
        return product.price * product.count * discount;
    },
    getCoupon(product){
      let i = this.cartIndex(product.id);
      if(this.cart2[i]) return this.cart2[i].coupon;
      return null;
    },
    couponRemove(product){
      let i = this.cartIndex(product.id);
      if(this.cart2[i]){
        this.cart2[i].coupon = null;
        this.cart2[i].discount = 0;
        this.cart2.sort();
      }
    },
    couponCheck(index){
      if(this.products[index].count > 0){
        let product = this.products[index];
        let i = this.cartIndex(product.id);
        // document.getElementById('icon'+index).innerHTML = '...';
        if(this.productCoupons[index] && this.cart2[i]){
            this.cart2[i].discount = 0;
            let vm = this;
            this.$http.post('couponcheck/'+this.productCoupons[index]+'/'+product.id)
            .then( response => response.json())
            .then(function (response) {
                vm.notifyVue('Valid Coupon', 'success');
                vm.cart2[i].coupon = this.productCoupons[index];
                vm.cart2[i].discount = response.discount;
                // document.getElementById('icon' + index).innerHTML = '✔';
                vm.cart2.sort();
              })
            .catch(function (error) {
                if(vm.cart2[i].coupon) delete(vm.cart2[i].coupon);
                // document.getElementById('icon' + index).innerHTML = '✘';
                vm.notifyVue(error.body.error, 'danger');
                vm.cart2.sort();
            })
        }else{
            if(this.cart2[i]){
                delete this.cart2[i].coupon;
                delete this.cart2[i].discount;
            }
            // document.getElementById('icon'+index).innerHTML = '';
        }
        this.cart2.sort();
      }
    },
    cartIndex(id){
        let arrayIndex = -1;
        this.cart2.forEach((element, index) => { if (element.id === id) arrayIndex = index });
        return arrayIndex;
    },
    totalCouponRemove(){
      this.totalCouponAccepted = false;
      this.totalCoupon = null;
      this.totalDiscount = 0;
      this.cart2.sort();
    },
    totalCouponCheck(){
        this.totalDiscount = 0;
        if (this.totalCoupon) {
            var coupons = [];
            this.cart2.forEach(element => {
                if(element.coupon) coupons.push(element.coupon);
            });
            var vm = this;
            this.$http.post('couponcheck/'+this.totalCoupon,{ coupons })
            .then( response => response.json())
            .then(function (response) {
                this.totalCouponAccepted = true;
                vm.totalDiscount = response.discount;
                // document.getElementById('icon').innerHTML = '✔';
                vm.cart2.sort();
            })
            .catch(function (error) {
                // document.getElementById('icon').innerHTML = '✘';
                vm.notifyVue(error.body.error, 'danger');
                vm.cart2.sort();
            })
        } else {
            this.cart2.sort();
        }
    },
    buyNow(){
      var vm = this;
      vm.payButton = false;
      this.$http.post('orders',{
        cart: this.cart2,
        totalCoupon: this.totalCoupon,
        total: this.totalWithFee,
        card: this.card,
        payment: this.payment,
        originalOrder: this.originalOrder
      })
      .then( response => response.json())
      .then(function (response) {
        vm.notifyVue(response.message, 'success');
        if(response.link){
          window.location.replace(response.link);
        }else{
          vm.paymentForm = false;
          vm.payButton = true;
          this.$router.push('/my/afterorder');
        }
      })
      .catch(function (error) {
        if (error.status == 403)  this.$router.push('/logout');
        if (error.status == 404)  {
          swal({
            title: this.$t('Product Not Exists'),
            text: this.$t('productNotExists'),
            showCancelButton: false,
            confirmButtonText: this.$t('notExists'),
            confirmButtonClass: 'btn btn-danger btn-fill',
            cancelButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false
          })
          .then(() => {
            this.$router.push('/my/products');
          });
        }
        
        if(error.status != 403 && error.status != 404) Object.keys(error.body.error).forEach(function (key) {
          vm.notifyVue(key+': '+error.body.error[key], 'danger');
        });
        vm.payButton = true;
      })
    },
    asList(list){
      if(!list) return null;
      return list.split("\n");
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
    n2br(val = null){
      if(!val) return null;
      return val.replace(/\n/g, "<br />");
    }
  },
  mounted() {
    if(this.resources.products){
      if(this.toReorder){
        this.originalOrder = this.toReorder;
        this.$http.get('details/'+this.toReorder)
          .then(response => response.json())
          .then(response => {
            if(response.productActive == 1){
              this.products = this.resources.products.filter((row) => row.id == response.productId);
              this.addProduct2(this.products[0]);
              this.cart2[0].fields = response.fields;
            }else{
              swal({
                title: this.$t('Product Not Exists'),
                text: this.$t('productNotExists'),
                showCancelButton: false,
                confirmButtonText: this.$t('notExists'),
                confirmButtonClass: 'btn btn-danger btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                buttonsStyling: false
              })
              .then(() => {
                this.$router.push('/my/products');
              });
            }
          })
          .catch(response => {
            if (response.status == 403)  this.$router.push('/logout');
        })
        this.setToReorder();
      }else{
        this.products = this.resources.products;
      }
    }else{
      this.apiResources();
    }
  }
};
</script>
<style scoped>
  .paymentlogo{
    display: block;
    margin: auto;
    height: 50px;
    width: auto;
    padding: 5px;
  }
  div.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index:999;
    margin-bottom: 10px;
    padding: 15px;
  }
</style>
